import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
// import { Pagination } from 'react-admin';
// requires refinement

const MiscPagination = () => {
    const { page, hasPreviousPage, hasNextPage, setPage } = useListContext();
    
    // console.log("pehla keyList :", keyList)

    // console.log("pehla lastKey :", lastKey)
    if (!hasPreviousPage && !hasNextPage) return null;
    return (
        <Toolbar>
            {hasPreviousPage &&
                <Button 
                    key="previous"
                    onClick={() => setPage(page-1)}
                    startIcon={<ChevronLeft />}
                >
                    Previous
                </Button>
            }
            {hasNextPage &&
                <Button 
                    key="next"
                    onClick={() => setPage(page + 1) }
                    startIcon={<ChevronRight />}
                >
                    Next                    
                </Button>
            }
        </Toolbar>
    );
}

export default MiscPagination;



// const MiscPagination = () => {
//     const { resource, filter, page, hasPreviousPage, hasNextPage, setPage } = useListContext();
//     const store = getStorage();
//     const keyList = store.getItem("keyList");
    
//     let reference = `${resource}`
//     // console.log(filter)
//     for (let key in filter){
//         console.log("----------------")
//         console.log(key,filter[key])
//         reference = reference.concat(`.${key}.${filter[key]}`)
//     }
//     console.log(reference)

    
//     // localStore.setItem("reference", reference)

//     // store.setItem("kahan hai", "pagination se se");
//     const lastKey = `${store.getItem("lastKey")}`

//     if (lastKey === "null") {
//         console.log("pehla keyList :", keyList)
//         console.log("pehla lastKey :", lastKey)
//     }

//     // console.log("pehla keyList :", keyList)

//     // console.log("pehla lastKey :", lastKey)
//     if (!hasPreviousPage && !hasNextPage) return null;
//     return (
//         <Toolbar>
//             {hasPreviousPage &&
//                 <Button 
//                     key="previous"
//                     onClick={() => setPage(page-1)}
//                     startIcon={<ChevronLeft />}
//                 >
//                     Previous
//                 </Button>
//             }
//             {hasNextPage &&
//                 <Button 
//                     key="next"
//                     onClick={() => {setPage(page + 1); store.setItem("exclusiveStartKey", lastKey)} }
//                     startIcon={<ChevronRight />}
//                 >
//                     Next                    
//                 </Button>
//             }
//         </Toolbar>
//     );
// }

// export default MiscPagination;