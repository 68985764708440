import { Layout, LayoutProps } from 'react-admin';
import {Menu} from './Menu';


// const CustomLayout: <Lay> = (props: LayoutProps) => <Layout {...props} menu={Menu} />;
    
export const CustomLayout = (props: LayoutProps) => {
    return (<Layout {...props} menu={Menu} />);
};

// export default CustomLayout