import { List, Datagrid, TextField, EditButton, ResourceContextProvider, FilterPayload, ShowButton } from 'react-admin';
import MiscPagination from '../components/MiscPagination';
// import { Chip } from '@mui/material';


type props = {
    filter?:FilterPayload
}


const ProductList = (props:props) => (
    <ResourceContextProvider value='products' >
        <List filter={props.filter} pagination={<MiscPagination />}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="category"/>
                <TextField source="name" />
                <TextField source="detail" />
                <TextField source="notes" />
                <TextField source="status" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    </ResourceContextProvider>
);


export default ProductList;