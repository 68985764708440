import * as React from 'react';
import { ArrayInput, Create, FormTab, SimpleFormIterator, TabbedForm, TextInput } from 'react-admin';
import { Grid } from '@mui/material';


import { ProductEditDetails } from './ProductEditDetails';

const ProductCreate = () => {
    return (
        <Create>
            {/* <TabbedForm defaultValues={{ status: "restricted" }}>*/}
            <TabbedForm >
                {/* <FormTab
                    label="products.tabs.cns"
                    sx={{ maxWidth: '40em' }}
                >
                    <ArrayInput source="attributes">
                        <SimpleFormIterator>
                            <TextInput source="ids" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    {/* <TextInput
                        autoFocus
                        source="image"
                        fullWidth
                        validate={required()}
                    />
                    <TextInput
                        source="thumbnail"
                        fullWidth
                        validate={required()}
                    /> */}
                {/* </FormTab> */}
                <FormTab
                    label="products.tabs.specifics"
                    // path="specifics"
                    sx={{ maxWidth: '40em' }}
                    // value="specifics"
                >
                    <Grid item xs={12} sm={4}>
                        <TextInput source='category' />
                    </Grid>
                    <TextInput fullWidth source="detail" />
                    <ProductEditDetails />
                    {/* <ArrayInput source="cns">
                        <SimpleFormIterator>
                            <TextInput source="ids" />
                        </SimpleFormIterator>
                    </ArrayInput> */}
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ProductCreate;