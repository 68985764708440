import { Edit, FormTab, TabbedForm, TextInput, useRecordContext } from "react-admin";
import { ContactUs } from "../types";

import { ContactEditDetails } from './ContactEditDetails';


const ContactTitle = () => {
    const record = useRecordContext<ContactUs>();
    return record ? 
        <span>
            Contact "{record.userName}" . ID "{record.id}"
        </span> : null;
};


const ContactEdit = () => (
    <Edit title={<ContactTitle />}>
        <TabbedForm>
            {/* <FormTab
                label="resources.contactUs.tabs.image"
                sx={{ maxWidth: '40em' }}
            >
                {"Image Display"}
                {/* <TextInput source="image" fullWidth/>
                <TextInput source="thumbnail" fullWidth/> */}
            {/* </FormTab> */}
            <FormTab
                label="specifics"
                sx={{ maxWidth: '40em' }}
            >
                <TextInput disabled fullWidth source='id' />
                <TextInput fullWidth source="details" />
                <ContactEditDetails />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ContactEdit