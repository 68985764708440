import * as React from 'react';
import { ArrayInput, Create, DateInput, FormTab, SelectInput, SimpleFormIterator, TabbedForm, TextInput } from 'react-admin';
import { Grid } from '@mui/material';


// import { ProductionLineEditDetails } from './ProductionLineEditDetails';

const ProductionLineCreate = () => {
    return (
        <Create>
            <TabbedForm>
                {/* <FormTab
                    label="resources.productionLines.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                    <TextInput
                        autoFocus
                        source="image"
                        fullWidth
                        validate={required()}
                    />
                    <TextInput
                        source="thumbnail"
                        fullWidth
                        validate={required()}
                    />
                </FormTab> */}
                <FormTab
                    label="productionLines.tabs.specifics"
                    // path="specifics"
                    sx={{ maxWidth: '40em' }}
                >
                    {/* <Grid item xs={12} sm={4}>
                        <TextInput source='id' />
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                        <SelectInput source='stage' choices={[{id:"proposed",name:"Proposed"}, {id:"ongoing", name:"Ongoing"}, {id:"completed", name:"Completed"}]}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="category" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="name" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectInput source="status" choices={[{id:"restricted",name:"Restricted"}, {id:"unrestricted", name:"Unrestricted"}]}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput fullWidth source="details" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DateInput source="expiryDate" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DateInput source="startDate" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DateInput source="comletionDate" />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <ArrayInput source="notes">
                            <SimpleFormIterator>
                                <TextInput source=''/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    
                    <Grid item xs={12} sm={8}>
                        <ArrayInput source="attributes">
                            <SimpleFormIterator>
                                <TextInput source="type" />
                                <TextInput source="language" />
                                <TextInput source="platform" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput fullWidth source="template" />
                    </Grid>
                    
                    <Grid item xs={0} sm={4}></Grid>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ProductionLineCreate;