import * as React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';

import customDataProvider from "./data/customDataProvider";
import products from "./products";
import {CustomLayout} from "./layout";
import Dashboard from "./layout/Dashboard";
import ProductionLineList from "./productionLines/ProductionLineList";
import ProductList from "./products/ProductList";
import contactUs from "./contactUs";
import ContactList from "./contactUs/ContactList";
import productionLines from "./productionLines";



// console.log(process.env)

const baseUrl = `${process.env.REACT_APP_SERVER_HOST}`
const dataProvider = customDataProvider(baseUrl);

const CustomAdmin = () => (
    <Admin dashboard={Dashboard} layout={CustomLayout} dataProvider={dataProvider} >
        <Resource name="products" {...products}/>
        <Resource name="contact-us" {...contactUs} />
        <Resource name="production-lines" {...productionLines}/>
        {/* <MyRoutes /> */}
        <CustomRoutes>
            <Route path="/products/all" element={<ProductList filter={{"category" : "all"}} />}/>
            <Route path="/products/app" element={<ProductList filter={{"category" : "app"}} />}/>
            <Route path="/products/design" element={<ProductList filter={{"category" : "design"}} />}/>
            <Route path="/products/art" element={<ProductList filter={{"category" : "art"}} />}/>
            <Route path="/production-lines/all" element={<ProductionLineList filter={{"stage" : "all"}} /> } />
            <Route path="/production-lines/ongoing" element={<ProductionLineList filter={{"stage" : "ongoing"}} /> } />
            <Route path="/production-lines/proposed" element={<ProductionLineList filter={{"stage" : "proposed"}} /> } />
            <Route path="/production-lines/completed" element={<ProductionLineList filter={{"stage" : "completed"}} /> } />
            <Route path="/contact-us" element={<ContactList /> } />
        </CustomRoutes>
    </Admin>
);

export default CustomAdmin;

// icon={InventoryIcon}