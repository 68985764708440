import {
    Edit,
    FormTab,
    SelectInput,
    TabbedForm,
    TextInput,
    useRecordContext,
} from 'react-admin';
import { ProductionLineEditDetails } from './ProductionLineEditDetails';
import { ProductionLine } from '../types';
import { Grid } from '@mui/material';


const ProductionLineTitle = () => {
    const record = useRecordContext<ProductionLine>();
    return record ? 
        <span>
            ProductionLine "{record.name}" . ID "{record.id}"
        </span> : null;
};

const ProductionLineEdit = () => (
    <Edit title={<ProductionLineTitle />}>
        <TabbedForm>
            <FormTab
                label="productionLines.tabs.image"
                sx={{ maxWidth: '40em' }}
            >
                {"Image Display"}
                {/* <TextInput source="image" fullWidth/>
                <TextInput source="thumbnail" fullWidth/> */}
            </FormTab>
            <FormTab
                label="productionLines.tabs.specifics"
                path="specifics"
                sx={{ maxWidth: '40em' }}
            >
                <Grid item xs={12} sm={4}>
                    <TextInput disabled source='id' />
                </Grid>                
                <Grid item xs={12} sm={4}>
                    <TextInput disabled source="category" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectInput source='stage' choices={[{id:"proposed",name:"Proposed"}, {id:"ongoing", name:"Ongoing"}, {id:"completed", name:"Completed"}]}/>
                </Grid>
                <TextInput fullWidth multiline source="detail" />
                <ProductionLineEditDetails />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ProductionLineEdit;