import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    useRecordContext,
} from 'react-admin';
import { ProductEditDetails } from './ProductEditDetails';
import { Product } from '../types';
import { Grid } from '@mui/material';


const ProductTitle = () => {
    const record = useRecordContext<Product>();
    return record ? 
        <span>
            Product "{record.name}" . ID "{record.id}"
        </span> : null;
};

const ProductEdit = () => (
    <Edit title={<ProductTitle />}>
        <TabbedForm>
            <FormTab
                label="products.tabs.image"
                sx={{ maxWidth: '40em' }}
            >
                {"Image Display"}
                {/* <TextInput source="image" fullWidth/>
                <TextInput source="thumbnail" fullWidth/> */}
            </FormTab>
            <FormTab
                label="products.tabs.specifics"
                path="specifics"
                sx={{ maxWidth: '40em' }}
            >
                <Grid item xs={12} sm={4}>
                    <TextInput disabled source='id' />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput disabled source='category' />
                </Grid>
                <TextInput fullWidth source="detail" />
                <ProductEditDetails />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ProductEdit;