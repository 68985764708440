import axios from 'axios';
import { stringify } from 'query-string';
import { DataProvider, getStorage } from 'ra-core';



export default (
    baseURL: string,
    httpClient = axios
) : DataProvider => ({
    getList: (resource, params) => {
        // console.log("process.env.NODE_ENV :",process.env.NODE_ENV)
        const localStore = getStorage();
        const { page , perPage } = params.pagination;
        const { field, order } = params.sort;
        const limit:number = perPage;
        // localStore.clear()


        let reference = `${resource}`
        for (let key in params.filter){
            reference = reference.concat(`.${key}.${params.filter[key]}`)
        }
        reference = reference.concat(".exclusiveStartKey")

        if (page===1) { localStore.removeItem(reference)}
        

        let keyListArray:string[] = []
        const keyListString = `${localStore.getItem(reference)}`
        
        if (keyListString === "null") {
            keyListArray.push(reference);
        } else {
            const tempArray = JSON.parse(keyListString);
            if (tempArray[0] === reference) {
                keyListArray = tempArray;
            } else {
                keyListArray.push(reference);
            }
        }




        const exclusiveStartKey : string = keyListArray[page]

        let query:any = {
            sort : JSON.stringify([field, order]),
            limit: limit,
            filter: JSON.stringify(params.filter),
        };

        if (exclusiveStartKey != null) {
            query["lastKey"] = exclusiveStartKey
        }
        const url = `/api/${resource}/?${stringify(query)}`;
        
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });

        return axiosInstance.get(url).then(({ data }) => {

            const newLastKey = data.lastKey;
            if (newLastKey != null) {
                const key = JSON.stringify(newLastKey);
                keyListArray[page + 1] = key;
                const temp = JSON.stringify(keyListArray)
                localStore.setItem(reference, temp)
            }
            return {
                data: data.items,
                pageInfo: {
                    hasNextPage: data["lastKey"] !== null? true : false,
                    hasPreviousPage: page > 1 ? true : false,
                }
            };
        });
    },




    getOne:  (resource, params) =>{
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url : string;
        url = `/api/${resource}/${params.id}/`
        return axiosInstance.get(url).then(({ data }) => {
            console.log(data)
            return {
                data : data.item
            }
        });
    },
// dummy
    getMany: (resource, params) =>{
        // TODO
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url;
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        url = `/api/${resource}?${stringify(query)}`;
        return axiosInstance.get(url).then(({ data }) => ({ data: data.item }));
        
    },
// dummy
    getManyReference: (resource, params) => {
        // TODO
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url;
        const query = {
            filter: JSON.stringify({ id: params.id }),
        };
        url = `/api/${resource}?${stringify(query)}`;
        return axiosInstance.get(url).then(({ data }) => ({ data: data.item }));
    },


    update: (resource, params) =>{
        
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url;
        url = `/api/${resource}/${params.id}/`
        return axiosInstance.put(url, params.data)
            .then(({data}) => {
                return {
                    data : data.item
                }
            })
    },

    updateMany: (resource, params) =>{
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url;
        return Promise.all(
            params.ids.map(id =>{
                url = `/api/${resource}/${id}/`
                return axiosInstance.put(url, params.data)
            })
        ).then(responses => ({ data: responses.map(({ data }) => data.id) }))
    },

    create: (resource, params) =>{
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url;
        url = `/api/${resource}/`
        return axiosInstance.post(url, params.data).then(({ data }) => ({
            data: { ...params.data, id: data.id },
        }));
    },


    delete: (resource, params) =>{
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        let url;
        url = `/api/${resource}/${params.id}/`;
        return axiosInstance.delete(url)
            .then(({ data }) => ({ data: data }))
    },
// incomplete
    deleteMany: (resource, params) =>{
        const axiosInstance = httpClient.create({
            baseURL: baseURL
        });
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };

        return axiosInstance(`${baseURL}/api/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            // body: JSON.stringify(params.data),
        }).then(({ data }) => ({ data: data.ids }));
    },
});
