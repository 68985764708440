import * as React from 'react';
import { useState } from 'react';

import { MenuItemLink, MenuProps, useSidebarState } from 'react-admin';
import LabelIcon from '@mui/icons-material/Label'
import SubMenu from './SubMenu';
import products from '../products';
import productionLines from '../productionLines';
import ContactUs from '../contactUs';


type MenuName = 'menuProducts' | 'menuProductionLines' ;


export const Menu = ({ dense = false }: MenuProps) => {
    // const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [state, setState] = useState({
        menuProducts : true,
        menuProductionLines : true
    });
    // const {handleToggle} = props;
    const [open] = useSidebarState();
    // const resources = useResourceDefinitions();

    const handleToggle = (menu:MenuName) => setState(state => ({ ...state, [menu]: !state[menu] }));

    return (
        <>
            {/* <DashboardMenuItem /> */}
            <SubMenu
                handleToggle={() => handleToggle('menuProducts')}
                isOpen={state.menuProducts}
                name="Products"
                icon={<products.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/products"
                    state={{ _scrollToTop: true }}
                    primaryText={`Products`}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/products/all"
                    state={{ _scrollToTop: true }}
                    primaryText={`all`}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/products/app"
                    state={{ _scrollToTop: true }}
                    primaryText={`App`}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/products/design"
                    state={{ _scrollToTop: true }}
                    primaryText={`design`}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/products/art"
                    state={{ _scrollToTop: true }}
                    primaryText={`Art`}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuProductionLines')}
                isOpen={state.menuProductionLines}
                name="ProductionLines"
                icon={<products.icon />}
                dense={dense}
            >
                {/* <MenuItemLink
                    to="/production-lines"
                    state={{ _scrollToTop: true }}
                    primaryText={`ProductionLines`}
                    leftIcon={<productionLines.icon />}
                    dense={dense}
                /> */}
                <MenuItemLink
                    to="/production-lines/all"
                    state={{ _scrollToTop: true }}
                    primaryText={`All`}
                    leftIcon={<productionLines.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/production-lines/proposed"
                    state={{ _scrollToTop: true }}
                    primaryText={`proposed`}
                    leftIcon={<productionLines.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/production-lines/ongoing"
                    state={{ _scrollToTop: true }}
                    primaryText={`ongoing`}
                    leftIcon={<productionLines.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/production-lines/completed"
                    state={{ _scrollToTop: true }}
                    primaryText={`completed`}
                    leftIcon={<productionLines.icon />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to="/contact-us"
                primaryText="ContactUs"
                leftIcon={<ContactUs.icon />}
                sidebarIsOpen = {open} />
            <MenuItemLink
                to="/dummy/app"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                sidebarIsOpen = {open} />
        </>
    );
}

// export default {Menu : Menu}


//  * // then, use this layout in the <Admin layout> prop:
//  * // in src/App.js
//  * import { Layout }  from './Layout';
//  *
//  * const App = () => (
//  *     <Admin layout={Layout} dataProvider={simpleRestProvider('http://path.to.my.api')}>
//  *         // ...
//  *     </Admin>
//  * );
//  */