import * as React from 'react';
import {
    SelectInput,
    TextInput,
} from 'react-admin';
import { Grid } from '@mui/material';


export const ContactEditDetails = () => (
    <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4}>
            <TextInput source="userName" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput 
                type="email"
                source="userEmail"
                isRequired
                fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectInput source={"purpose"} choices={[{id:"design",name:"Design"}, {id:"developer", name:"Developer"}, {id:"casual", name:"Casual"}]}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source="locality" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source="state" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source="country" />
        </Grid>        
    </Grid>
);