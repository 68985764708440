import { List, Datagrid, TextField, EditButton, FilterPayload, DateField, NumberField, ResourceContextProvider } from 'react-admin';
import MiscPagination from '../components/MiscPagination';


type props = {
    filter?:FilterPayload
}


const ProductionLineList = (props:props) => (
    <ResourceContextProvider value='production-lines' >
        <List filter={props.filter} pagination={<MiscPagination />}>
            <Datagrid rowClick="edit">
                <TextField source="stage"/>
                <TextField source="category"/>
                <TextField source="detail" />
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="status" />
                <DateField source="expiryDate"/>
                <DateField source="startDate"/>
                <DateField source="completionDate"/>
                <NumberField source="voteCount"/>
                <NumberField source="priorityCount"/>
                <NumberField source="wtdPriority"/>
                <NumberField source="progress" options={{style : "percent"}}/>
                <EditButton />
            </Datagrid>
        </List>
    </ResourceContextProvider>
)






export default ProductionLineList;