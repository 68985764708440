import * as React from 'react';
import { Create, FormTab, SelectInput, TabbedForm, TextInput } from 'react-admin';
import { Grid } from '@mui/material';


const ContactCreate = () => {
    return (
        <Create>
            <TabbedForm>
                <FormTab
                    label="contactUs.tabs.specifics"
                    // path="specifics"
                    sx={{ maxWidth: '40em' }}
                >
                    {/* <Grid item xs={12} sm={4}>
                        <TextInput source="category" />
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                        <TextInput source="userName" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput 
                            type="email"
                            source="userEmail"
                            isRequired
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectInput source={"purpose"} choices={[{id:"design",name:"Design"}, {id:"developer", name:"Developer"}, {id:"casual", name:"Casual"}]}/>
                    </Grid>
                    <TextInput fullWidth source="details" />
                    {/* <ContactEditDetails /> */}
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ContactCreate;