import * as React from 'react';
import {
    ArrayInput,
    DateInput,
    NumberInput,
    SelectInput,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import { Grid } from '@mui/material';

export const ProductionLineEditDetails = () => (
    <Grid container columnSpacing={2}>
        
        <Grid item xs={12} sm={4}>
            <TextInput source="name" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectInput source="status" choices={[{id:"restricted",name:"Restricted"}, {id:"unrestricted", name:"Unrestricted"}]}/>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
            <TextInput source="detail" />
        </Grid> */}
        <Grid item xs={12} sm={4}>
            <DateInput source="expiryDate" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <DateInput source="startDate" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <DateInput source="comletionDate" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <NumberInput step={1} source="voteCount" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <NumberInput step={1} source="priorityCount" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <NumberInput max={3} min={0} source="wtdPriority" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <NumberInput source="progress" max={100} min={0} options={{style : "percent"}}/>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={8}>
            <ArrayInput source="notes">
                <SimpleFormIterator>
                    <TextInput source=''/>
                </SimpleFormIterator>
            </ArrayInput>
        </Grid>
        
        <Grid item xs={12} sm={8}>
            <ArrayInput source="attributes">
                <SimpleFormIterator>
                    <TextInput source="type" />
                    <TextInput source="language" />
                    <TextInput source="platform" />
                </SimpleFormIterator>
            </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source="template" />
        </Grid>
        
        <Grid item xs={0} sm={4}></Grid>
        
        
    </Grid>
);