import { List, Datagrid, TextField, EditButton, ResourceContextProvider, FilterPayload, EmailField, DateField } from 'react-admin';
import MiscPagination from '../components/MiscPagination';


type props = {
    filter?:FilterPayload
}


const ContactList = (props:props) => (
    <ResourceContextProvider value='contact-us' >
        <List filter={props.filter} pagination={<MiscPagination />}>
            <Datagrid rowClick="edit">
                {/* <TextArrayField record={} source="category">
                    <SingleFieldList>
                        <ChipField source=''/>
                    </SingleFieldList>
                </TextArrayField> */}
                <TextField source="userName"/>
                <EmailField source="userEmail" />
                <TextField source="id" />
                <TextField source="details" />
                <TextField source="purpose" />
                <TextField source="locality"/>
                <TextField source="state"/>
                <TextField source="country"/>
                <DateField source="timeStamp" showTime/>
                <EditButton />
            </Datagrid>
        </List>
    </ResourceContextProvider>
);


export default ContactList;