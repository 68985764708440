import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import ProductionLineList from './ProductionLineList';
import ProductionLineEdit from './ProductionLineEdit';
import ProductionLineCreate from './ProductionLineCreate';
// import ProductionLineOngoingList from '../components/ProductionLineOngoingList';

export default {
    list: ProductionLineList,
    // list: ProductionLineOngoingList,
    create: ProductionLineCreate,
    edit: ProductionLineEdit,
    icon: Inventory2TwoToneIcon,
};