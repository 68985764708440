import * as React from 'react';
import {
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import { Grid } from '@mui/material';



export const ProductEditDetails = () => (
    <Grid container >
        <Grid item xs={12} sm={4}>
            <TextInput source="name" />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectInput source="status" choices={[{id:"restricted",name:"Restricted"}, {id:"unrestricted", name:"Unrestricted"}]}/>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
            <TextInput fullWidth source="detail" />
        </Grid> */}
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={8}>
            <ArrayInput source="notes">
                <SimpleFormIterator>
                    <TextInput source=''/>
                </SimpleFormIterator>
            </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={8}>
            <ArrayInput source="attributes">
                <SimpleFormIterator>
                    <TextInput source="type" />
                    <TextInput source="language" />
                    <TextInput source="platform" />
                </SimpleFormIterator>
            </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={8}>
            <ArrayInput source="cns">
                <SimpleFormIterator>
                    <TextInput source=''/>
                </SimpleFormIterator>
            </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}>
            <TextInput fullWidth source="template" />
        </Grid>

    </Grid>
);
