import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactCreate from './ContactCreate';
import ContactEdit from './ContactEdit';
import ContactList from './ContactList';

export default {
    list: ContactList,
    create: ContactCreate,
    edit: ContactEdit,
    icon: ContactMailIcon,
};