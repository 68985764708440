import React from 'react';
// import './App.css';
import CustomAdmin from './CustomAdmin';


function App() {
  return (
    <CustomAdmin />
  )
};

export default App;
